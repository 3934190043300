import { createGlobalStyle } from "styled-components";

import CaslonReg from "common/fonts/Williams_Caslon_Text-Regular.woff2";
import CaslonItal from "common/fonts/Williams_Caslon_Text-Italic.woff2";

export default createGlobalStyle`

    @font-face {
        font-family: 'WCaslon';
        src: url(${CaslonReg}) format('woff2');
        font-weight: normal;
        font-style: normal;
    }

    @font-face {
        font-family: 'WCaslon';
        src: url(${CaslonItal}) format('woff2');
        font-weight: normal;
        font-style: italic;
    }

    html {
        font-size: 130%;
        -webkit-font-smoothing: antialiased;
        @media screen and (min-width: 640px) {
            font-size:135%;
        }
        @media screen and (min-width: 960px) {
            font-size:175%;
        }
        font-family: "WCaslon";
        line-height: 1.33333;
        padding: 0;
        margin: 0;
        width: 100%;
        background:#fcfcfc;
    }
    body {
        padding: 0.75rem;
    }
    * {
        box-sizing: border-box !important;
    }

    a {
        color: inherit;
    }

    p {
        text-indent: 2rem;
        &:first-child,
        &:last-child {
            text-indent: 0;
        }
        margin: 0 0 1rem 0;
        color: #333;
        > span, 
        > em > span {
            color: blue;
            > span, 
            > em > span {
                color: red;
                > span,
                > em > span {
                    color: green;
                    > span,
                    > em > span {
                        color: purple;
                        > span,
                        > em > span {
                            color: orange;
                            > span,
                            > em > span {
                                color: darkcyan;
                            }
                        }
                    }
                }
            }
        }
    }

    ::selection {
        background-color: transparent;
    }
`;
