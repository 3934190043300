import React, { Component } from "react";
import styled from "styled-components";
import queryString from "query-string";

import S from "Components/Stretch/Stretch";

const SignOff = styled.p`
    margin-top: 2rem;
    margin-right: 0;
    margin-left: auto;
    width: 13rem;
    text-indent: 0;
    @media screen and (max-width: 640px) {
        margin-left: 0;
    }
`;

const Header = styled.div`
    text-align: right;
    margin-bottom: 2rem;
`;

const addLevel = (children, level, openLevel) => {
    return React.Children.map(children, child => {
        if (!React.isValidElement(child)) {
            return child;
        }
        if (child.props && child.props.children) {
            if (child.props.short) {
                // we know this is a stretch from short prop... slightly hacky
                return React.cloneElement(child, {
                    level: level + 1,
                    expanded: openLevel >= level + 1,
                    children: addLevel(
                        child.props.children,
                        level + 1,
                        openLevel
                    )
                });
            } else {
                return React.cloneElement(child, {
                    children: addLevel(child.props.children, level, openLevel)
                });
            }
        }
        return child;
    });
};

class Content extends Component {
    vars = queryString.parse(window.location.search);
    name = this.vars["n"] ? atob(this.vars["n"]) : false;
    date = new Date();

    constructor(props) {
        super(props);
        if (this.vars["encode"]) {
            // redirect helper
            this.encoded = btoa(this.vars["encode"]);
            window.location.href = `${window.location.protocol}//${
                window.location.host
            }${window.location.pathname}?n=${this.encoded}`;
        }
    }

    content = (
        <>
            <Header>
                {this.date.toLocaleDateString("en-US", {
                    weekday: "long",
                    year: "numeric",
                    month: "long",
                    day: "numeric"
                })}
            </Header>
            {this.name && (
                <>
                    <p style={{ textIndent: 0 }}>
                        To{" "}
                        <cite
                            style={{
                                whiteSpace: "pre-wrap",
                                fontStyle: "normal"
                            }}
                        >
                            {this.name.replace(/\|/g, `\n`)}
                        </cite>
                    </p>
                    <p style={{ textIndent: 0 }}>
                        Dear {this.name.split(" ")[0]},
                    </p>
                </>
            )}
            <p>
                <S short="Darpa–Cedar">
                    Darpa–Cedar is a publication of{" "}
                    <S short="Darpa">
                        Darpa (similar to yet not{" "}
                        <S short="DARPA">
                            Defence Advanced Research Projects Agency
                        </S>
                        )
                    </S>
                    , a <S short="small">secretly visible</S>,{" "}
                    <S short="semi-private">
                        nonprofit, self-directed,{" "}
                        <S short="disorganised">
                            bidirectionally ordered (<em>open</em> as in source
                            but{" "}
                            <em>
                                <S short="private">
                                    private both as in sequestered and
                                </S>
                            </em>{" "}
                            as in{" "}
                            <S short="press">
                                press, which Anatole Claudin defines as being
                                “set up in a monastery, a palace, a residence or
                                a{" "}
                                <S short="private house">
                                    private house (or in my case, in food
                                    courts, university libraries, and on trains)
                                </S>
                                , not the residence of a professional printer”
                            </S>
                            )
                        </S>{" "}
                        but more-or-less co-operative
                    </S>{" "}
                    <S short="press">
                        press (not as in 'printing press', but as in{" "}
                        <em>exertion</em>)
                    </S>
                    , and
                </S>{" "}
                is a{" "}
                <S short="continuous anthology">
                    continuous anthology — in the sense that the publication
                    appears on the fly, in various media, and will be published
                    in a sequence of unbounded length —
                </S>{" "}
                of{" "}
                <S short="lost causes">
                    lost causes.{" "}
                    <div style={{ height: "1rem", width: "1rem" }} />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;A ‘lost
                    cause’ is{" "}
                    <S short={"an overdetermined term, of course"}>
                        a cause without an effect. It is an uncatalysed
                        catalyst. A lost cause is too far gone;
                        too-little-too-late; too quixotic; not worth it; not
                        working; uneconomical or inefficient; starry-eyed; in
                        disrepair; superannuated; disobedient
                    </S>
                    . A lost cause, for{" "}
                    <S short="the purposes of Darpa–Cedar">
                        the purposes of Darpa–Cedar and at the heart of the
                        Darpa press
                    </S>
                    , is a{" "}
                    <S short="way in which the world is not-so">
                        way in which the world is{" "}
                        <S short="not-so">
                            <em>not-so</em>: a clumsy term used as a kind of{" "}
                            <S short="negative set function">
                                negation machine for{" "}
                                <S short="locating">
                                    locating (in resignation)
                                </S>{" "}
                                ‘a resistance to the world as it is’ (
                                <a
                                    href="https://www.poetryfoundation.org/poems/58315/at-least-two-types-of-people"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    via
                                </a>
                                )
                            </S>
                        </S>
                        . We know already that the form of a lost cause is
                        useful as a material for counterfactual logic: we can
                        imagine{" "}
                        <S short="other, different scenarios">
                            other, different scenarios — events that might have
                            happened, had things been{" "}
                            <S short="different">
                                different (what if I had never been born? what
                                if the Internet was not{" "}
                                <S
                                    short="‘a nightmare honkytonk
                                prison’"
                                >
                                    ‘a nightmare honkytonk prison, noisy and
                                    colourful and wholly misbegotten’ (
                                    <a
                                        href="http://geeks-bearing-gifts.com/"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        via
                                    </a>
                                    )
                                </S>
                                ?)
                            </S>{" "}
                            —{" "}
                        </S>{" "}
                        in order to comparatively understand events that have
                        happened. But we’re interested, too, in the form of the
                        lost cause beyond its thinking-value. For instance, a
                        lost cause can yet{" "}
                        <S
                            short="have side effects in the
            unfolding of the world"
                        >
                            have{" "}
                            <S short="side effects">
                                side effects, through varieties of{" "}
                                <S
                                    short="agonising
                                absence"
                                >
                                    agonising absences — in{" "}
                                    <em>being known to be lost</em> —
                                </S>
                            </S>{" "}
                            in the{" "}
                            <S short="unfolding">
                                unfolding, collapsing, balancing-up, reflecting,
                                and catastrophic reproduction
                            </S>{" "}
                            of the world. (And, in any case, let’s ask: is the{" "}
                            <em>loss</em> in a lost cause is an event in
                            itself?)
                        </S>
                    </S>
                </S>
                .
            </p>
            {/* <p>—</p> */}
            <p>
                <S short="I’d like to invite you">
                    I invite you (but please recommend anyone who you think
                    might be interested in contributing)
                </S>{" "}
                to contribute an{" "}
                <S short="entry">
                    entry, which should take the form of a{" "}
                    <S short="‘text-based’ artwork">
                        ‘text-based’ artwork — in that it should be made up of
                        text-only, representable onscreen, but take can any{" "}
                        <S short="form">
                            form (document, poem, report, image-representation,
                            letter, exultation, log, list, misunderstanding,
                            remedy, blueprint, analogy, question, and so forth)
                        </S>{" "}
                        and{" "}
                        <S short="scale">
                            scale (proof-of-concept, glance, experiment,
                            complete specification)
                        </S>{" "}
                        within that restriction
                    </S>
                    ,
                </S>{" "}
                into the{" "}
                <S short="anthology">
                    <S short="anthology">
                        <S short="anthology">
                            anthology (inside which you might{" "}
                            <S short="respond to">
                                respond to, update, dispute, rotate, fall in
                                love with
                            </S>{" "}
                            other artwork-documents in the anthology)
                        </S>{" "}
                        — as in{" "}
                        <em>
                            <S short="collected or picked flowers">
                                a sum of differences
                            </S>
                        </em>{" "}
                        —{" "}
                    </S>{" "}
                    (because we want to collect them as a book or songs and/or
                    an axis of combination){" "}
                </S>{" "}
                of{" "}
                <S short="artwork-documents">
                    ‘artwork-documents’: a term which{" "}
                    <S short="adapts, somewhat,">
                        tries to ask: can an artwork be a kind of testimony or{" "}
                        <S short="technical specification? ">
                            <S short="technical specification">
                                overview, in spirit and structure, of the way a
                                thing is (or will be (or was)) in the world
                            </S>
                            ? (i.e., be proof both of the causes that are
                            collected in the anthology and of their{" "}
                            <S short="lostness">
                                admixture of unavailability and utter presence
                            </S>
                            ){" "}
                        </S>
                        via{" "}
                    </S>{" "}
                    the form of the{" "}
                    <S short="RFC memo">
                        <S short="RFC">Request-for-Comments</S>{" "}
                        <S short="memo: ">
                            memoranda — a officeways-loaded term, but only
                            really ‘something to be remembered’ —
                        </S>{" "}
                        more-or-less a{" "}
                        <S short="type of document">
                            type of technical-specification document, written by
                            the{" "}
                            <S short="‘technology community’">
                                ‘technology community’ (which, at some point,
                                comes to mean{" "}
                                <S short="agents">quasi-alienated clerks</S> of
                                Internet companies of a{" "}
                                <S short="particular">dreadful, unfathomable</S>{" "}
                                scale)
                            </S>
                            ,
                        </S>{" "}
                        at the heart of the making of the{" "}
                        <S short="Internet">
                            series of protocols, applications, and
                            ways-of-thinking that make up how we are technically
                            together
                        </S>
                    </S>
                </S>
                , about a lost cause of{" "}
                <S short="your choosing">
                    your choosing: something like a{" "}
                    <em>
                        <S short="pet">beloved and fugitively-available</S> lost
                        cause
                    </em>
                    . (Ours is{" "}
                    <S short="other possible internets">
                        other{" "}
                        <S short="possible internets">
                            less troublesome internets, about which we wonder if
                            their falling-apart and not-being-so is the
                            orientation and paving for what <em>is</em>)
                        </S>{" "}
                        — including Ted Nelson’s Xanadu, from which{" "}
                        <S short="the concept of ‘stretchtext’">
                            the way of scaling the level of detail in this
                            document{" "}
                            <S short="up and down">
                                up and down (which Nelson imagined would be done
                                with a physical{" "}
                                <S short="‘throttle’">
                                    ‘throttle’, on a public machine in what
                                    would be more or less a shopping centre
                                    kiosk
                                </S>
                                , a detail that I am sorry to jettison)
                            </S>
                        </S>{" "}
                        has been adapted in the production of this invitation —
                    </S>{" "}
                    that were dreamt of or planned up and that were set aside in
                    the unfolding of the{" "}
                    <S short="Internet as of now">
                        existing Internet, which we think of as{" "}
                        <S short="catastrophic">
                            adamently and continuously shoring up the inequities
                            of the world
                        </S>
                    </S>
                    , but I invite you to{" "}
                    <S short="think about other cases">
                        think about cases including: something designed but
                        unimplemented; something in the wrong time; something
                        adopted in a way which exhausts it; something which was
                        afterwards to begin with; something undecidable,
                        vaporous, or secretly agonised-over
                    </S>
                    )
                </S>
                .
            </p>
            {/* <p>—</p> */}
            <p>
                <S short="This">Contributing to Darpa–Cedar</S> is{" "}
                <S short="voluntary">
                    <S short="me asking you for help">
                        me helplessly asking you for help
                    </S>
                </S>
                ,{" "}
                <S short="but, in return,">
                    so, in return — not as{" "}
                    <S short="payment">closure of a promise</S> but{" "}
                    <S short="reciprocity">
                        a form of useful <S short="likeness">difference</S>
                        -swapping
                    </S>{" "}
                    —{" "}
                </S>{" "}
                <S short="I’ll">I, as secretary of Darpa press, will</S>{" "}
                <S short="make and send you">
                    make and send you, utilising{" "}
                    <S short="the sole physical machinery of the press,">
                        a computerised{" "}
                        <S short="sewing machine">
                            sewing machine, kept in my{" "}
                            <S short="study">
                                study, (much as there were many private presses
                                with small hand-presses in living rooms)
                            </S>
                        </S>
                        ,
                    </S>
                </S>{" "}
                <S short="an art work">
                    <S short="an art work–">
                        an art work, which{" "}
                        <S short="functions">
                            functions, in its moving around the world on your
                            walls and tables or slipped within another
                            publication,
                        </S>{" "}
                        like a{" "}
                    </S>
                    <S short="broadside">
                        <S short="broadside">
                            <S short="broadside">
                                broadside—which more-or-less means{" "}
                                <S short="something printed on one side only">
                                    something printed on one side only, which is
                                    to say that it must also have one markless
                                    side{" "}
                                </S>{" "}
                                —{" "}
                            </S>{" "}
                            (a{" "}
                            <S short="format">
                                way of making and distributing printed matter
                            </S>{" "}
                            with roots in both{" "}
                            <S short="poetic">
                                poetic — where a poster-like poem, often
                                letterpressed, might inaugurate and circulate a
                                new publication or be an poetic end in itself —{" "}
                            </S>{" "}
                            and{" "}
                            <S short="political">
                                political — where some declaration or agony
                                might appear as a song on a wall or slipped into
                                another publication —{" "}
                            </S>{" "}
                            speech)
                        </S>
                        , or ‘printed{" "}
                        <S short="public surface’">
                            public surface', as in: it will be in{" "}
                            <S short="circulation">
                                active mediation of{" "}
                                <S short="transindividuation">
                                    the{" "}
                                    <S short="effect">
                                        effect that we claim which an art work
                                        might incur,{" "}
                                    </S>{" "}
                                    which can be described as « 🌀 »{" "}
                                </S>
                            </S>{" "}
                            of which this invitation-prospectus is more-or-less
                            included
                        </S>
                    </S>
                    , as part of{" "}
                    <S short="the next publication in this series">
                        the next publication in this{" "}
                        <S short="series">series of pressed objects</S>: a
                        distributed parade of{" "}
                        <S short="ghosts">
                            {" "}
                            <S short="ghosts">ideas without bodies</S> inside
                            the{" "}
                            <S short="Internet">
                                {" "}
                                technical spacelessness of our being together
                            </S>
                        </S>
                    </S>
                </S>
                .
            </p>

            <p>Kindest,</p>
            <SignOff>
                Rowan McNaught
                <br />
                (Secretary,{" "}
                <a
                    href="//darpa.press"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Darpa
                </a>
                )
            </SignOff>
        </>
    );

    render() {
        return <>{addLevel(this.content, 0, this.props.openLevel)}</>;
    }
}

export default Content;
