import React, { useState } from "react";
import styled from "styled-components";

import Slider from "Components/Slider/Slider";
import GlobalStyle from "Components/GlobalStyle/GlobalStyle";
import Content from "Components/Content/Content";

const Container = styled.div`
    margin: 1.5rem auto 0;
    max-width: 27rem;
`;

const SliderContainer = styled.div`
    position: fixed;
    left: 1.5rem;
    top: 1.5rem;
    @media screen and (max-width: 767px) {
        display: none;
    }
`;

function App() {
    const [openLevel, setOpenLevel] = useState(0);
    return (
        <>
            <Container>
                <Content openLevel={openLevel} />
            </Container>
            <SliderContainer>
                <Slider
                    value={openLevel}
                    max={6}
                    handleChange={(e, value) => setOpenLevel(Math.abs(value))}
                />
            </SliderContainer>
        </>
    );
}

export default () => (
    <>
        <GlobalStyle />
        <App />
    </>
);
