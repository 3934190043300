import React from "react";

import { withStyles } from "@material-ui/core/styles";
import styled from "styled-components";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import Slider from "@material-ui/lab/Slider";

const theme = createMuiTheme({
    typography: {
        useNextVariants: true
    },
    palette: {
        primary: {
            main: "#333"
        }
    }
});

const styles = {
    root: {
        display: "flex",
        height: window.innerHeight - 86
    },
    slider: {
        padding: "0px"
    },
    track: {
        // actually trackafter
        /* backgroundColor: "#333", */
        backgroundColor: "#eee",
        width: "3px",
        zIndex: 1
    },
    trackAfter: {
        // actually the track
        opacity: 1,
        background: "#333",
        zIndex: 1
    },
    thumb: {
        /* backgroundColor: "#333", */
        width: "18px",
        height: "18px"
    }
};

const thumbColours = [
    "black",
    "blue",
    "red",
    "green",
    "purple",
    "orange",
    "darkcyan"
];

const Ticks = styled.div`
    position: absolute;
    top: 0;
    left: -8px;
    width: 16px;
    height: calc(100vh - 86px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
`;

const Tick = styled.div`
    width: 100%;
    background: ${props => props.color};
    height: 2.2px !important;
    z-index: 1;
`;

const Labels = styled.div`
    position: absolute;
    top: 0;
    left: 8px;
    font-size: 10px;
    font-weight: 600;
    font-family: Helvetica, Arial;
    letter-spacing: 1px;
    height: calc(100vh - 86px);
    text-transform: uppercase;
`;

const LabelMore = styled.div`
    transform: rotate(-90deg);
    bottom: 0;
    left: 11px;
    position: absolute;
    white-space: nowrap;
    width: 19px;
`;

const LabelLess = styled.div`
    transform: rotate(-90deg);
    top: 0;
    position: absolute;
    white-space: nowrap;
    width: 60px;
    left: -8px;
`;

const VerticalSlider = ({ classes, value, max, handleChange }) => {
    return (
        <>
            <div>
                <MuiThemeProvider theme={theme}>
                    <div className={classes.root}>
                        <Slider
                            classes={{
                                container: classes.slider,
                                track: classes.track,
                                thumb: classes.thumb,
                                trackAfter: classes.trackAfter
                            }}
                            value={-value}
                            min={-max}
                            max={0}
                            step={1}
                            onChange={handleChange}
                            vertical
                        />
                    </div>
                </MuiThemeProvider>
            </div>
            <Ticks>
                {thumbColours.map(color => (
                    <Tick color={color} key={color} />
                ))}
            </Ticks>
            <Labels>
                <LabelMore>← More</LabelMore>
                <LabelLess>Less →</LabelLess>
            </Labels>
        </>
    );
};

export default withStyles(styles)(VerticalSlider);
