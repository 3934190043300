import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import transition from "styled-transition-group";

const StretchedContent = transition.span`
    &:enter {
        background-color: rgba(240, 240, 240, 1);
    }
    &:enter-active {
        background-color: rgba(240, 240, 240, 0);
        transition: background-color 1500ms ease-in;
    }
`;

const ShortContent = styled.a`
    cursor: pointer;
    /* border-bottom: 1px solid rgba(240, 240, 240, 1); */
    text-decoration: underline;
    text-decoration-color: rgba(210, 210, 210, 1);
    &:hover {
        text-decoration-color: rgba(160, 160, 160, 1);
    }
`;

export default ({ children, short, expanded }) => {
    const [isExpanded, setIsExpanded] = useState(expanded);
    const prevExpanded = usePrevious(expanded);

    useEffect(() => {
        if (prevExpanded !== expanded) {
            setIsExpanded(expanded);
        }
    }, [expanded, isExpanded, prevExpanded]);

    return (
        <>
            <StretchedContent
                unmountOnExit
                in={isExpanded}
                exit={false}
                timeout={1500}
            >
                {children}
            </StretchedContent>
            {!isExpanded && (
                <ShortContent onClick={() => setIsExpanded(!isExpanded)}>
                    {short}
                </ShortContent>
            )}
        </>
    );
};

function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}
